import React, { createContext, useState, useEffect } from "react";
import AuthService from "../Services/AuthService";

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    async function validateCredentials() {
        // Aquí puedes validar el token, por ejemplo, haciendo un request a tu API.
        let response = await AuthService.validateToken();
        if (response && !response.error) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        validateCredentials()
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const AuthContext = createContext();

export const useAuth = () => React.useContext(AuthContext);