import { useState } from "react";
import AuthService from "../Services/AuthService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";


function Login() {

    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();

    const [user, setUser] = useState({
        username: '',
        password: ''
    });


    async function onLogin() {

        let response = await AuthService.login(user);

        if (response.error) {
            alert(response.error);
        } else {
            AuthService.saveCredentials(response);
            // Redirect to home
            console.log('Login successful');
            setIsAuthenticated(true);
            navigate("/");  // Redirige al Home

        }
    }




    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-6">

                    <h3 className="mb-4 mt-5">
                        Login
                    </h3>

                    <div className="card">
                        <div className="card-header">
                            Welcome to the login page. Please enter your email and password to access the system.
                        </div>

                        <div className="card-body">
                            <div className="form-group mb-3">
                                <label>Username</label>
                                <input type="text"
                                    className="form-control"
                                    id="exampleInputUsername"
                                    aria-describedby="usernameHelp"
                                    value={user.username}
                                    onChange={(e) => setUser({ ...user, username: e.target.value })}
                                    placeholder="Enter username" />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>

                            <div className="form-group mb-3">
                                <label>Password</label>
                                <input type="password"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    value={user.password}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    placeholder="Password" />
                            </div>

                            <button
                                onClick={onLogin}
                                className="btn btn-primary">Submit</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login;