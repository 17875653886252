const Requests = {
    get: fetchGet,
    getWithAuth: async (url) => {
        let credentials = getCredentials();

        let headers = {
            'Content-Type': 'application/json'
        };

        if (credentials && credentials.token) {
            headers['Authorization'] = `Bearer ${credentials.token}`;
        }

        return fetchGet(url, headers);
    },
    post: async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(`(error) - in http get: ${url} - ${err}`, err);
            return err;
        }
    },
    put: async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(`(error) - in http get: ${url} - ${err}`, err);
            return err;
        }
    }
}

async function fetchGet(url, headers) {
    try {
        const response = await fetch(url, { headers })
        const data = await response.json();
        return data
    } catch (err) {
        console.log(`(error) - in http get: ${url} - ${err}`, err);
        return err;
    }
}

function getCredentials() {
    let credentials = localStorage.getItem('nobouncy.credentials');
    return credentials ? JSON.parse(credentials) : null;
}

export default Requests