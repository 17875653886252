import { ACTIVATE_UPLOAD, CREATE_SMTP_ACCOUNT, GET_AVAILABLE_ACCOUNTS, GET_DEFAULT_CONFIGURATION, GET_LATEST_INITIAL_EMAIL_SENT, GET_LATEST_TIMERS_LOGS, GET_MESSAGES, GET_SMTP_ACCOUNT_DETAIL, GET_SMTP_ACCOUNTS, GET_UPLOAD_DETAIL, GET_UPLOADS, SAVE_MESSAGE, SEND_SMTP_EMAIL, START_BOUNCE_TIMER, START_INITIAL_TIMER, UPDATE_DEFAULT_CONFIGURATION, UPDATE_MESSAGE, UPDATE_SMTP_ACCOUNT, UPLOAD_CSV_EMAILS } from "../constants";
import Requests from "./Requests";


const ApiService = {

    uploadFile: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return fetch(UPLOAD_CSV_EMAILS, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => data)
            .catch(error => error);
    },
    getUploads: () => {
        return Requests.get(GET_UPLOADS);
    },
    getUploadDetail(id) {
        return Requests.get(GET_UPLOAD_DETAIL + '/' + id);
    },
    getMessages: () => {
        return Requests.get(GET_MESSAGES);
    },
    saveMessage: (message) => {
        return Requests.post(SAVE_MESSAGE, message);
    },
    updateMessage: (message) => {
        return Requests.put(UPDATE_MESSAGE, message);
    },
    startInitialTimer: () => {
        return Requests.post(START_INITIAL_TIMER, {})
    },
    startBounceTimer: () => {
        return Requests.post(START_BOUNCE_TIMER, {})
    },
    getLatestInitialEmailsSent: () => {
        return Requests.get(GET_LATEST_INITIAL_EMAIL_SENT);
    },
    getLatestTimersLogs: () => {
        return Requests.get(GET_LATEST_TIMERS_LOGS);
    },
    createSMTPAccount(account) {
        return Requests.post(CREATE_SMTP_ACCOUNT, account);
    },
    getSMTPAccounts() {
        return Requests.get(GET_SMTP_ACCOUNTS);
    },
    getSMTPAccountDetail(id) {
        return Requests.get(`${GET_SMTP_ACCOUNT_DETAIL}?_id=${id}`);
    },
    updateSMTPAccount(account) {
        return Requests.post(UPDATE_SMTP_ACCOUNT, account);
    },
    sendSMTPEmail(emailParams) {
        return Requests.post(SEND_SMTP_EMAIL, emailParams);
    },
    getDefaultConfiguration() {
        return Requests.get(GET_DEFAULT_CONFIGURATION);
    },
    updateDefaultConfiguration(config) {
        return Requests.post(UPDATE_DEFAULT_CONFIGURATION, config);
    },
    getAvailableAccounts() {
        return Requests.get(GET_AVAILABLE_ACCOUNTS);
    },
    activateUpload(upload_id, active) {
        return Requests.post(ACTIVATE_UPLOAD, { upload_id, active })
    }

}

export default ApiService;