import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext, useAuth } from "./AuthContext"; // Contexto de autenticación

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        // Puedes mostrar un spinner o cualquier indicador de carga.
        return <div>Loading...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;