import { AUTH_LOGIN, AUTH_VALIDATE } from '../constants';

import Requests from './Requests';

const AUTH_KEY = 'nobouncy.credentials';

const AuthService = {
    login,
    signOut,
    validateToken,
    saveCredentials,
}

async function login({ username, password }) {
    return Requests.post(AUTH_LOGIN, { username, password })
}

async function validateToken() {
    return Requests.getWithAuth(AUTH_VALIDATE);
}

async function signOut() {
    clearCredentials();
}

function saveCredentials(credentials) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(credentials));
}

function clearCredentials() {
    localStorage.removeItem(AUTH_KEY);
}

function getCredentials() {
    let credentials = localStorage.getItem('nobouncy.credentials');
    return credentials ? JSON.parse(credentials) : null;
}

async function isLogged() {
    let credentials = getCredentials();
    if (credentials) {
        return true;
    } else {
        return false;
    }
}



export default AuthService;