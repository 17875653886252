const API_URL = 'https://api.nobouncy.io/api';
// const API_URL = 'http://localhost:3000/api';

const UPLOAD_CSV_EMAILS = API_URL +'/nobouncy/upload/csv';
const GET_UPLOADS = API_URL + '/nobouncy/uploads';
const GET_UPLOAD_DETAIL = API_URL + '/nobouncy/uploads/detail';

const GET_MESSAGES = API_URL + '/nobouncy/messages/get';
const SAVE_MESSAGE = API_URL + '/nobouncy/messages/save';
const UPDATE_MESSAGE = API_URL + '/nobouncy/messages/update';

const START_INITIAL_TIMER = API_URL + '/nobouncy/timer/initial';
const START_BOUNCE_TIMER = API_URL + '/nobouncy/timer/bounce';

const GET_LATEST_INITIAL_EMAIL_SENT = API_URL + '/nobouncy/emails/initial/latest';
const GET_LATEST_TIMERS_LOGS = API_URL + '/nobouncy/timer/logs';

const CREATE_SMTP_ACCOUNT = API_URL + '/nobouncy/accounts/smtp/create';
const GET_SMTP_ACCOUNTS = API_URL + '/nobouncy/accounts/smtp/get';
const GET_SMTP_ACCOUNT_DETAIL = API_URL + '/nobouncy/accounts/smtp/detail';
const UPDATE_SMTP_ACCOUNT = API_URL + '/nobouncy/accounts/smtp/update';
const SEND_SMTP_EMAIL = API_URL + '/nobouncy/accounts/smtp/test';

const GET_AVAILABLE_ACCOUNTS = API_URL + '/nobouncy/accounts/available';
const ACTIVATE_UPLOAD = API_URL + '/nobouncy/uploads/activate';

const GET_DEFAULT_CONFIGURATION = API_URL + '/nobouncy/config/general/get';
const UPDATE_DEFAULT_CONFIGURATION = API_URL + '/nobouncy/config/general/update';

const AUTH_LOGIN = API_URL + '/nobouncy/auth/login';
const AUTH_VALIDATE = API_URL + '/nobouncy/auth/validate';

export {
    UPLOAD_CSV_EMAILS,
    GET_UPLOADS,
    GET_UPLOAD_DETAIL,
    GET_MESSAGES,
    SAVE_MESSAGE,
    UPDATE_MESSAGE,
    START_INITIAL_TIMER,
    START_BOUNCE_TIMER,
    GET_LATEST_INITIAL_EMAIL_SENT,
    GET_LATEST_TIMERS_LOGS,
    CREATE_SMTP_ACCOUNT,
    GET_SMTP_ACCOUNTS,
    GET_SMTP_ACCOUNT_DETAIL,
    UPDATE_SMTP_ACCOUNT,
    SEND_SMTP_EMAIL,
    GET_DEFAULT_CONFIGURATION,
    UPDATE_DEFAULT_CONFIGURATION,
    GET_AVAILABLE_ACCOUNTS,
    ACTIVATE_UPLOAD,
    AUTH_LOGIN,
    AUTH_VALIDATE
}


