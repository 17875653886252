import logo from './logo.svg';
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import Import from './Import/Import';
import Results from './Results/Results';
import Uploads from './Uploads/Uploads';
import UploadDetail from './Uploads/UploadDetail';
import Messages from './Messages/Messages';
import Accounts from './Accounts/Accounts';
import AccountsNew from './Accounts/AccountsNew';
import AccountsEdit from './Accounts/AccountsEdit';
import AccountSendEmail from './Accounts/AccountsSendEmail';
import GeneralConfig from './GeneralConfig/GeneralConfig';
import BasicAlert from './Alerts/BasicAlert';
import LoadingAlert from './Alerts/LoadingAlert';
import Login from './Login/Login';
import ProtectedRoute from './Auth/ProtectedRoute';
import { AuthProvider } from './Auth/AuthContext';

const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "import", element: <Import /> },
  { path: "uploads/:id", element: <UploadDetail /> },
  { path: "uploads", element: <Uploads /> },
  { path: "messages", element: <Messages /> },
  { path: "configuration", element: <GeneralConfig /> },
  { path: "accounts", element: <Accounts /> },
  { path: "accounts/new", element: <AccountsNew /> },
  { path: "accounts/:id/edit", element: <AccountsEdit /> },
  { path: "accounts/:id/test", element: <AccountSendEmail /> }
]

function App() {

  function renderProtectedRoutes() {
    return protectedRoutes.map((route, index) => {
      return (
        <Route key={index}
          path={route.path}
          element={<ProtectedRoute>{route.element}</ProtectedRoute>} />
      )
    })
  }

  return (
    <main>
      <AuthProvider>
        <BrowserRouter>
          <BasicAlert />
          <LoadingAlert />
          <Sidebar />
          <div style={{ flex: 1, background: '#F4F7FA', overflowY: 'scroll' }}>
            <Routes>
              <Route path="login" element={<Login />} />

              {renderProtectedRoutes()}
            </Routes>
          </div>

        </BrowserRouter>

      </AuthProvider >
    </main>
  );
}

export default App;
