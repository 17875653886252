import {
    Link,
    Navigate,
    useLocation,
} from "react-router-dom";
import AuthService from "../Services/AuthService";


function Sidebar() {

    const location = useLocation();

    if (location.pathname === '/login') {
        return null;
    }

    function onClickSignOut() {
        let confirm = window.confirm('Are you sure you want to sign out?');
        if (!confirm) {
            return;
        }

        AuthService.signOut();
        // Reload the page to clear the state
        window.location.reload();

    }


    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{ width: '280px', height: '100vh' }}>
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-4">
                    <img src="/iworker-logo-white.png" width={125} alt="" />
                </span>
            </a>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">

                <li>
                    <Link to="/" className="nav-link text-white">
                        <i className="fas fa-home me-2"></i>
                        Dashboard
                    </Link>
                </li>

                <li>
                    <Link to="import" className="nav-link text-white">
                        <i className="fas fa-file-import me-2"></i>
                        Import
                    </Link>
                </li>

                <li>
                    <Link to="uploads" className="nav-link text-white">
                        <i className="fas fa-upload me-2"></i>
                        Uploads
                    </Link>
                </li>

                <li>
                    <Link to="messages" className="nav-link text-white">
                        <i className="far fa-comments me-2"></i>
                        Messages
                    </Link>
                </li>

                <li>
                    <Link to="accounts" className="nav-link text-white">
                        <i className="fas fa-users me-2"></i>
                        SMTP Accounts
                    </Link>
                </li>

                <li>
                    <Link to="configuration" className="nav-link text-white">
                        <i className="fas fa-cog me-2"></i>
                        Configuration
                    </Link>
                </li>
            </ul>
            <hr />
            <div className="dropdown">
                <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <strong>User</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" onClick={onClickSignOut}>Sign out</a></li>
                </ul>
            </div>
        </div>
    )

}

export default Sidebar;